module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-30},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dot-allison-website","short_name":"dot-allison","start_url":"/","background_color":"#FFF1E1","theme_color":"#FFF1E1","display":"minimal-ui","icon":"src/images/dot-allison-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e459e57bf46208904008041eafc5e3ae"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
