import React, {useState, useEffect} from "react"

import "../styles/reset.css"
import "../styles/styles.scss"
import { siteHolder, mainHolder } from '../styles/Layout.module.scss'

import Header from "./header"
import MainNav from "./MainNav"

import useWindowSize from '../hooks/useWindowSize'

import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'

const Layout = ({ children, path }) => {

  const [isHome, setIsHome ] = useState(path==='/')
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const size = useWindowSize()

  const mainVariants = {
    pre: {
      opacity:0
    },
    vis: {
      opacity:1,
      transition: {
        when: "beforeChildren",
        type: "tween",
        duration: 0.5,
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    },
    post: {
      opacity:0,
      transition: {
        when: "afterChildren",
        staggerDirection:-1,
        staggerChildren: 0.05
      }
    }
  }

  useEffect(() => {
      setIsHome(path==='/')
  }, [path, setIsHome])



  useEffect(()=>{
    const breakpoint = 640

    if(size.width < breakpoint)
    setIsSmallScreen(true)

    if(size.width > breakpoint)
    setIsSmallScreen(false)

    if(((size.width / size.height) > 1.7) && (size.height < 600))
    setIsSmallScreen(true)


  }, [size, setIsSmallScreen])


  return (
    <div className={siteHolder}>
      <AnimateSharedLayout>
        <Header isHome={isHome}/>
        <AnimatePresence exitBeforeEnter>
          <motion.main
            className={mainHolder}
            key={path}
            variants={mainVariants}
            initial="pre"
            animate="vis"
            exit="post"
            layout
            >
            {children}
          </motion.main>
        </AnimatePresence>
      </AnimateSharedLayout>

      <MainNav isSmallScreen={isSmallScreen} />
    </div>
  )
}


export default Layout
