import React, { useState, useEffect } from 'react'
import {Link} from 'gatsby'
import {navHolder, hamburgerHolder, navHolderSmallScreen} from '../styles/MainNav.module.scss'
import '../styles/hamburgers.min.css'
import { AnimatePresence, motion } from 'framer-motion'

export default function MainNav({isSmallScreen}) {
  const [navOpen, setNavOpen] = useState(false)

  const navVariants = {
    pre: { x:"-100%" },
    visible: { x:"0%",
    transition: {
      type: "tween",
      duration:0.4
    }
  },
    post: { x:"-100%",
    transition: {
      type: "tween",
      duration:0.4
    }
  }
  }

  useEffect(()=>{
    setNavOpen(!isSmallScreen)
  },[isSmallScreen, setNavOpen])

  return (
    <>
    { isSmallScreen && 
    <div className={hamburgerHolder}>
      <button className={`hamburger hamburger--slider ${navOpen ? 'is-active' : ''}`} type="button" onClick={()=>setNavOpen(!navOpen)}>
        <span className="hamburger-box">
          <span className="hamburger-inner">Toggle Nav</span>
        </span>
      </button>
    </div> }
    <AnimatePresence>
      {(!isSmallScreen || (navOpen)) &&
        <motion.nav variants={navVariants} initial="pre" animate="visible" exit="post" className={`${navHolder} ${isSmallScreen && navHolderSmallScreen}`}>
          <ul>
            <li><Link onClick={()=>{setNavOpen(false)}} to="/" activeClassName="active">Home</Link></li>
            <li><Link onClick={()=>{setNavOpen(false)}} to="/about" activeClassName="active">About</Link></li>
            <li><Link onClick={()=>{setNavOpen(false)}} to="/music" activeClassName="active">Music</Link></li>
            <li><Link onClick={()=>{setNavOpen(false)}} to="/sightandsound" activeClassName="active">Sight & Sound</Link></li>
            <li><Link onClick={()=>{setNavOpen(false)}}to="/lyrics" activeClassName="active">Lyrics</Link></li>
            <li><a onClick={()=>{setNavOpen(false)}} href="https://sarecordings.com/release/230013-dot-allison-heart-shaped-scars" target="_blank" rel="noreferrer">Shop</a></li>
            <li><Link onClick={()=>{setNavOpen(false)}} to="/mailing-list" activeClassName="active">Mailing List</Link></li>
          </ul>
        </motion.nav>
      }
    </AnimatePresence>
    </>
  )
}
