import * as React from "react"
import { motion } from 'framer-motion'
import {headerHolder, headerHeader} from '../styles/Header.module.scss'

const Header = ({isHome}) => {

  const homeHeader = {
    height:"25vh",
    width:"187px",
    transition: {
      type: "tween",
      ease: "easeInOut",
      duration: 0.6
    }
  }
  const notHomeHeader = {
    height:"6vh",
    width:"145px", 
    transition: {
      type: "tween",
      ease: "easeInOut",
      duration: 0.6
    }
  }

return (
  <motion.header className={headerHolder} animate={isHome ? homeHeader : notHomeHeader} layout>
      { 
      isHome
        ? 
        <motion.h1 className={headerHeader}>
          Dot Allison  
        </motion.h1>
        :
        <motion.h2 className={headerHeader}>Dot Allison</motion.h2>
      }
  </motion.header>
)
}


export default Header
